<template>
  <div id="leftBottom">
    <div class="lb-title">2022年4月残疾人就业出勤情况</div>
    <dv-scroll-board class="lb-content" :config="config" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "负责人", "应/实出勤人数"],
        data: [
          // ["浙江省淳安县千岛湖镇", "27/27"],
          // ["浙江省淳安县里商乡", "24/24"],
          // ["浙江省淳安县文昌镇", "18/18"],
          // ["浙江省淳安县瑶山乡", "17/17"],
          // ["浙江省淳安县浪川乡", "12/12"],
          // ["浙江省淳安县梓桐镇", "12/12"],
          // ["浙江省淳安县威坪镇", "11/11"],
          // ["浙江省淳安县姜家镇", "10/10"],
          // ["浙江省淳安县枫树岭镇", "9/9"],
          // ["浙江省淳安县大墅镇", "8/8"],
          // ["浙江省淳安县临岐镇", "4/4"],
          // ["浙江省淳安县左口乡", "4/4"],
          // ["浙江省淳安县中洲镇", "3/3"],
          // ["浙江省淳安县屏门乡", "3/3"],
          // ["浙江省淳安县王阜乡", "3/3"],
          // ["浙江省淳安县安阳乡", "2/2"],
          // ["浙江省淳安县汾口镇", "2/2"],
          // ["浙江省淳安县界首乡", "2/2"],
          // ["浙江省淳安县富文乡", "1/1"],
          // ["浙江省淳安县金峰乡", "1/1"],
          ["文昌镇公益劳动型帮扶性就业基地", "程敏燕", "22/22"],
          ["瑶山乡公益劳动型帮扶性就业基地", "汪勇平", "17/17"],
          ["里商乡公益劳动型帮扶性就业基地", "金熙琳", "16/16"],
          ["千岛湖镇公益劳动型帮扶性就业基地", "项五莲", "14/14"],
          ["千岛湖镇职康劳动型帮扶性就业基地", "项五莲", "10/10"],
          ["里商乡职康劳动型帮扶性就业基地", "金熙琳", "9/9"],
          ["姜家镇公益劳动型帮扶性就业基地", "洪国庭", "8/8"],
          ["枫树岭镇公益劳动型帮扶性就业基地", "魏菊琴", "8/8"],
          ["浪川乡职康劳动型帮扶性就业基地", "王玉萍", "8/8"],
          ["威坪镇职康劳动型帮扶性就业基地", "王东华", "6/6"],
          ["临歧镇公益劳动型帮扶性就业基地", "何春梅", "5/5"],
          ["威坪镇公益劳动型帮扶性就业基地", "王东华", "5/5"],
          ["大墅镇公益劳动型帮扶性就业基地", "方燚凯", "4/4"],
          ["大墅镇职康劳动型帮扶性就业基地", "方燚凯", "4/4"],
          ["左口乡公益劳动型帮扶性就业基地", "方瑾", "4/4"],
          ["浪川乡公益劳动型帮扶性就业基地", "王玉萍", "4/4"],
          ["中洲镇公益劳动型帮扶性就业基地", "余舒薇", "3/3"],
          ["安阳乡公益劳动型帮扶性就业基地", "张剑恒", "2/2"],
          ["屏门乡职康劳动型帮扶性就业基地", "胡晓泸", "2/2"],
          ["枫树岭镇职康劳动型帮扶性就业基地", "魏菊琴", "2/2"],
          ["梓桐镇公益劳动型帮扶性就业基地", "黄浩", "2/2"],
          ["汾口镇公益劳动型帮扶性就业基地", "李中前", "2/2"],
          ["王阜乡职康劳动型帮扶性就业基地", "王俊", "2/2"],
          ["界首乡公益劳动型帮扶性就业基地", "余洁", "2/2"],
          ["富文乡公益劳动型帮扶性就业基地", "汪书敏", "1/1"],
          ["王阜乡公益劳动型帮扶性就业基地", "王俊", "1/1"],
          ["金峰乡公益劳动型帮扶性就业基地", "洪丽娟", "1/1"],
        ],
        rowNum: 8, //表格行数
        headerHeight: 50,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [70, 400, 120, 220],
        align: ["center", "left", "center","center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#leftBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(158, 191, 232);
  }
  .lb-content {
    width: 100%;
    height: 92%;
  }
}
</style>
