<template>
  <div id="leftTop">
    <div id="age"
         style="width:100%;height:50%"></div>
    <div id="edcution"
         style="width:100%;height:50%"></div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        ageData: [
          {
            name: '初中以及下',
            value: 129,
          },
          {
            name: '高中',
            value: 24,
          },
          {
            name: '大专及以上',
            value: 6,
          }
        ],
        edcutionData: [
          {
            name: '19-29',
            value: 6,
          },
          {
            name: '30-49',
            value: 83,
          },
          {
            name: '50-60',
            value: 70,
          }
        ],
        color: ['#2ca1ff', '#0adbfa', '#febe13', '#65e5dd', '#7b2cff', '#fd5151', '#f071ff', '#85f67a'],
      }
    },
    components: {

    },
    mounted() {
      this.showAge();
      this.showEdcution();
    },
    methods: {
      showAge() {
        let ageChart = this.$echarts.init(document.getElementById('age'))
        var max = Math.max(...this.ageData.map((item) => item.value));
        var data = [];
        for (var i = 0; i < this.ageData.length; i++) {
          data.push(
            {
              value: this.ageData[i].value,
              name: this.ageData[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: this.color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false
              }
            }
          );
        }

        let option = {
          color: this.color,
          title: {
            text: '残疾人受教育程度',
            // left: 'center',
            textStyle: {
              color: 'rgb(158, 191, 232)',
            },
            top: 4
          },
          tooltip: {
            show: true,
            confine: true,
          },
          legend: {
            show: false,
          },
          series: [
            {
              name: '',
              type: 'pie',
              clockWise: false,
              radius: ['40%', '60%'],
              hoverAnimation: false,
              center: ['50%', '60%'],
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}\n{d}%',
                textStyle: {
                  fontSize: 16,
                  color: '#fff',
                },
              },
              data: data,
            },
          ],
        };
        ageChart.setOption(option)
        ageChart.setOption(option)
        window.addEventListener("resize", function () {
          ageChart.resize(); //下面可以接多个图
        });
      },
      showEdcution() {
        let myChart = this.$echarts.init(document.getElementById('edcution'))
        var max = Math.max(...this.edcutionData.map((item) => item.value));
        var data = [];
        for (var i = 0; i < this.edcutionData.length; i++) {
          data.push(
            {
              value: this.edcutionData[i].value,
              name: this.edcutionData[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: this.color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false
              }
            }
          );
        }

        let option = {
          color: this.color,
          title: {
            text: '残疾人就业年龄段',
            // left: 'center',
            textStyle: {
              color: 'rgb(158, 191, 232)',
            },
            top: 0
          },
          tooltip: {
            show: true,
            confine: true,
          },
          legend: {
            show: false,
          },
          series: [
            {
              name: '',
              type: 'pie',
              clockWise: false,
              radius: ['40%', '60%'],
              hoverAnimation: false,
              center: ['50%', '60%'],
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}岁\n{d}%',
                textStyle: {
                  fontSize: 16,
                  color: '#fff',
                },
              },
              data: data,
            },
          ],
        };
        myChart.setOption(option)
        window.addEventListener("resize", function () {
          myChart.resize(); //下面可以接多个图
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  $box-width: 100%;
  $box-height: 480px;

  #leftTop {
    padding: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
  }
</style>
