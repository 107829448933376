<template>
  <div id="rightBottom">
    <div class="lb-title">残疾人就业收入情况</div>
    <dv-scroll-board class="lb-content" :config="config" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "收入金额"],
        data: [
          // ["浙江省淳安县里商乡", "597,405.9"],
          // ["浙江省淳安县千岛湖镇", "559,305.63"],
          // ["浙江省淳安县文昌镇", "260,139.6"],
          // ["浙江省淳安县浪川乡", "246,349.8"],
          // ["浙江省淳安县瑶山乡", "199,167.36"],
          // ["浙江省淳安县威坪镇", "190,673.88"],
          // ["浙江省淳安县枫树岭镇", "145,073.88"],
          // ["浙江省淳安县大墅镇", "140,513.88"],
          // ["浙江省淳安县屏门乡", "65,751.84"],
          // ["浙江省淳安县姜家镇", "64,317.96"],
          // ["浙江省淳安县左口乡", "55,675.92"],
          // ["浙江省淳安县王阜乡", "50,160.0"],
          // ["浙江省淳安县临岐镇", "47,511.84"],
          // ["浙江省淳安县中洲镇", "35,633.88"],
          // ["浙江省淳安县梓桐镇", "34,677.96"],
          // ["浙江省淳安县界首乡", "34,677.96"],
          // ["浙江省淳安县安阳乡", "30,595.92"],
          // ["浙江省淳安县汾口镇", "23,755.92"],
          // ["浙江省淳安县金峰乡", "18,717.96"],
          // ["浙江省淳安县富文乡", "11,877.96"],
          // ["浙江省淳安县宋村乡", "9,120.0"],

          // ["文昌镇公益劳动型帮扶性就业基地", "程敏燕", "397,472.46"],
          // ["里商乡公益劳动型帮扶性就业基地", "金熙琳", "360,011.4"],
          // ["千岛湖镇职康劳动型帮扶性就业基地", "项五莲", "266,822.97"],
          // ["里商乡职康劳动型帮扶性就业基地", "金熙琳", "228,274.5"],
          // ["瑶山乡公益劳动型帮扶性就业基地", "汪勇平", "199,167.36"],
          // ["千岛湖镇公益劳动型帮扶性就业基地", "项五莲", "173,389.8"],
          // ["浪川乡职康劳动型帮扶性就业基地", "王玉萍", "167,395.92"],
          // ["枫树岭镇公益劳动型帮扶性就业基地", "魏菊琴", "145,073.88"],
          // ["威坪镇职康劳动型帮扶性就业基地", "王东华", "105,835.92"],
          // ["大墅镇职康劳动型帮扶性就业基地", "方燚凯", "87,595.92"],
          // ["浪川乡公益劳动型帮扶性就业基地", "王玉萍", "78,953.88"],
          // ["临歧镇公益劳动型帮扶性就业基地", "何春梅", "56,631.84"],
          // ["屏门乡职康劳动型帮扶性就业基地", "胡晓泸", "56,631.84"],
          // ["左口乡公益劳动型帮扶性就业基地", "方瑾", "55,675.92"],
          // ["威坪镇公益劳动型帮扶性就业基地", "王东华", "55,197.96"],
          // ["大墅镇公益劳动型帮扶性就业基地", "方燚凯", "43,797.96"],
          // ["姜家镇公益劳动型帮扶性就业基地", "洪国庭", "41,517.96"],
          // ["中洲镇公益劳动型帮扶性就业基地", "余舒薇", "35,633.88"],
          // ["梓桐镇公益劳动型帮扶性就业基地", "黄浩", "34,677.96"],
          // ["界首乡公益劳动型帮扶性就业基地", "余洁", "34,677.96"],
          // ["王阜乡职康劳动型帮扶性就业基地", "王俊", "31,920.0"],
          // ["安阳乡公益劳动型帮扶性就业基地", "张剑恒", "30,595.92"],
          // ["汾口镇公益劳动型帮扶性就业基地", "李中前", "23,755.92"],
          // ["金峰乡公益劳动型帮扶性就业基地", "洪丽娟", "18,717.96"],
          // ["王阜乡公益劳动型帮扶性就业基地", "王俊", "18,240.0"],
          // ["富文乡公益劳动型帮扶性就业基地", "汪书敏", "11,877.96"],
          // ["枫树岭镇职康劳动型帮扶性就业基地", "魏菊琴", "9,120.0"],

          // ],
          ["文昌镇公益劳动型帮扶性就业基地", "397,472.46"],
          ["里商乡公益劳动型帮扶性就业基地", "360,011.4"],
          ["千岛湖镇职康劳动型帮扶性就业基地", "266,822.97"],
          ["里商乡职康劳动型帮扶性就业基地", "228,274.5"],
          ["瑶山乡公益劳动型帮扶性就业基地", "199,167.36"],
          ["千岛湖镇公益劳动型帮扶性就业基地", "173,389.8"],
          ["浪川乡职康劳动型帮扶性就业基地", "167,395.92"],
          ["枫树岭镇公益劳动型帮扶性就业基地", "145,073.88"],
          ["威坪镇职康劳动型帮扶性就业基地", "105,835.92"],
          ["大墅镇职康劳动型帮扶性就业基地", "87,595.92"],
          ["浪川乡公益劳动型帮扶性就业基地", "78,953.88"],
          ["临歧镇公益劳动型帮扶性就业基地", "56,631.84"],
          ["屏门乡职康劳动型帮扶性就业基地", "56,631.84"],
          ["左口乡公益劳动型帮扶性就业基地", "55,675.92"],
          ["威坪镇公益劳动型帮扶性就业基地", "55,197.96"],
          ["大墅镇公益劳动型帮扶性就业基地", "43,797.96"],
          ["姜家镇公益劳动型帮扶性就业基地", "41,517.96"],
          ["中洲镇公益劳动型帮扶性就业基地", "35,633.88"],
          ["梓桐镇公益劳动型帮扶性就业基地", "34,677.96"],
          ["界首乡公益劳动型帮扶性就业基地", "34,677.96"],
          ["王阜乡职康劳动型帮扶性就业基地", "31,920.0"],
          ["安阳乡公益劳动型帮扶性就业基地", "30,595.92"],
          ["汾口镇公益劳动型帮扶性就业基地", "23,755.92"],
          ["金峰乡公益劳动型帮扶性就业基地", "18,717.96"],
          ["王阜乡公益劳动型帮扶性就业基地", "18,240.0"],
          ["富文乡公益劳动型帮扶性就业基地", "11,877.96"],
          ["枫树岭镇职康劳动型帮扶性就业基地", "9,120.0"],
        ],
        rowNum: 8, //表格行数
        headerHeight: 50,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [60, 400, 150],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#rightBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(158, 191, 232);
  }
  .lb-content {
    width: 100%;
    height: 92%;
  }
}
</style>
