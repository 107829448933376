<template>
  <div id="rigthTop">
    <div class="lb-title">岗位分布就业人数</div>
    <dv-capsule-chart :config="config"
                      class="lb-content" />
  </div>
</template>

<script>

  export default {
    data() {
      return {
        config: {
          data: [
            {
              name: '生态环境管护员',
              value: 65
            },
            {
              name: '后勤保障员',
              value: 50
            },
            {
              name: '疫情管控员',
              value: 44
            }
          ],
          colors: ['#e062ae', '#32c5e9', '#96bfff'],
          unit: '人',
          showValue: true
          // data: [
          //   {
          //     name: '南阳',
          //     value: 167
          //   },
          //   {
          //     name: '周口',
          //     value: 123
          //   },
          //   {
          //     name: '漯河',
          //     value: 98
          //   },
          //   {
          //     name: '郑州',
          //     value: 75
          //   },
          //   {
          //     name: '西峡',
          //     value: 66
          //   },
          // ],
          // colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
          // unit: '单位',
          // showValue: true
        }
        //    

      }
    },
    components: {

    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  $box-width: 100%;
  $box-height: 480px;

  #rigthTop {
    padding: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    .lb-title {
      width: 100%;
      height: 8%;
      // display: flex;
      // align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: rgb(158, 191, 232);
    }
    .lb-content {
      width: 100%;
      height: 92%;
    }
  }

  ::v-deep .dv-capsule-chart .capsule-item div {
    height: 18px;
  }
  ::v-deep .dv-capsule-chart .capsule-item {
    height: 20px;
  }
  ::v-deep .dv-capsule-chart .label-column {
    font-size: 16px;
    margin-top: 5px;
  }
  ::v-deep .dv-capsule-chart .unit-label {
    font-size: 16px;
  }
  ::v-deep
    .dv-capsule-chart
    .capsule-item
    .capsule-item-column
    .capsule-item-value {
    font-size: 16px;
  }
</style>
